@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

:root {
  --reactBlue: #61dafb;
  --reactGray: #282c34;
  --gold: #c29f61;
  --lightBlack: #181818;
  --darkGray: #333333;
}

section {
  padding: 0 10%;
}

@media (min-width: 768px) {
  .section {
    padding: 0 15%;
  }
}

.section-title {
  margin: 1rem 0 2rem 0;
}
